import {
  AVLCategoryBand1,
  AVLCategoryBand2,
  AVLCategoryBand3,
  AVLCategoryBand4,
  AVLCategoryBand5,
  AVLCategoryBand6,
  AVLCategoryNotIdentified,
  AVLPortableAlerted,
  AVLPortableAtStation,
  AVLPortableAvailable,
  AVLPortableDispatchable,
  AVLPortableEmergency,
  AVLPortableNotAvailable,
  AVLPortableNotKnown,
  AVLPortableOnScene,
  AVLPortableProceeding,
  AVLPortableResponding,
  AVLStatusAlerted,
  AVLStatusAtStation,
  AVLStatusAvailable,
  AVLStatusDispatchable,
  AVLStatusEmergency,
  AVLStatusNotAvailable,
  AVLStatusNotKnown,
  AVLStatusOnScene,
  AVLStatusProceeding,
  AVLStatusResponding,
  AVLStatusUndispatchable,
  Field,
  FieldGroup,
  StaticIconWrapper,
} from "@app/design-system";
import capitalize from "lodash/capitalize";
import getConfig from "next/config";
import React from "react";
import styled from "styled-components";

const {
  publicRuntimeConfig: { COP_ENABLED },
} = getConfig();

const StyledText = styled.div`
  padding-top: 2px;
  ${(p) => p.theme.typography.variants.footnote}
`;

const Styled3ColGrid = styled.div`
  display: grid;
  grid-template-columns: auto minmax(0, 1fr) minmax(0, 1fr);
  gap: 8px;
`;

const Styled2ColGrid = styled.div`
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  gap: 8px;
`;

interface CategoryDisplay {
  icon: ReactSVGComponent;
  category: string;
  description: string;
}

const categories: CategoryDisplay[] = [
  {
    icon: AVLCategoryNotIdentified,
    category: "Not Identified",
    description: "",
  },
  {
    icon: AVLCategoryBand1,
    category: "Cat. 1-6",
    description: "Medium/Heavy Tanker",
  },
  { icon: AVLCategoryBand2, category: "Cat. 7-9", description: "Light Tanker" },
  {
    icon: AVLCategoryBand3,
    category: "Cat. 10-11",
    description: "Light/Medium Tanker",
  },
  {
    icon: AVLCategoryBand4,
    category: "Cat. 12",
    description: "Personnel Transport",
  },
  {
    icon: AVLCategoryBand5,
    category: "Cat. 13",
    description: "Bulk Water Carrier",
  },
  {
    icon: AVLCategoryBand6,
    category: "Cat. 16-19",
    description: "Operational Support",
  },
];

export const AVL_STATUS: Record<string, ReactSVGComponent> = {
  ...(COP_ENABLED && { ALERTED: AVLStatusAlerted }),
  NOT_KNOWN: AVLStatusNotKnown,
  AT_STATION: AVLStatusAtStation,
  AVAILABLE: AVLStatusAvailable,
  DISPATCHABLE: AVLStatusDispatchable,
  ...(COP_ENABLED && { EMERGENCY: AVLStatusEmergency }),
  ON_SCENE: AVLStatusOnScene,
  ...(COP_ENABLED && { PROCEEDING: AVLStatusProceeding }),
  RESPONDING: AVLStatusResponding,
  NOT_AVAILABLE: AVLStatusNotAvailable,
  UNDISPATCHABLE: AVLStatusUndispatchable,
};

interface PortableCategoryStatusDisplay {
  icon: ReactSVGComponent;
  description: string;
}

export const AVL_PORTABLE_ICONS: readonly PortableCategoryStatusDisplay[] = [
  {
    icon: AVLPortableAlerted,
    description: "Alerted",
  },
  {
    icon: AVLPortableAtStation,
    description: "At station",
  },
  {
    icon: AVLPortableAvailable,
    description: "Available",
  },
  {
    icon: AVLPortableDispatchable,
    description: "Dispatchable",
  },
  {
    icon: AVLPortableEmergency,
    description: "Emergency",
  },
  {
    icon: AVLPortableNotAvailable,
    description: "Not available",
  },
  {
    icon: AVLPortableNotKnown,
    description: "Not known",
  },
  {
    icon: AVLPortableOnScene,
    description: "On scene",
  },
  {
    icon: AVLPortableProceeding,
    description: "Proceeding",
  },
  {
    icon: AVLPortableResponding,
    description: "Responding",
  },
];

const AVLLegend = () => {
  const formatStatus = (status: string) => {
    return capitalize(status.replace("_", " "));
  };

  return (
    <FieldGroup>
      <Field label="Vehicle category">
        <Styled3ColGrid>
          {categories.map(({ icon, category, description }) => (
            <React.Fragment key={category}>
              <StaticIconWrapper icon={icon} size="sm" />
              <StyledText>{category}</StyledText>
              <StyledText>{description}</StyledText>
            </React.Fragment>
          ))}
        </Styled3ColGrid>
      </Field>
      <Field label="Vehicle status">
        <Styled2ColGrid>
          {Object.keys(AVL_STATUS).map((key) => (
            <React.Fragment key={key}>
              <StaticIconWrapper icon={AVL_STATUS[key]} size="sm" />
              <StyledText>{formatStatus(key)}</StyledText>
            </React.Fragment>
          ))}
        </Styled2ColGrid>
      </Field>
      {COP_ENABLED && (
        <Field label="Portable radio status">
          <Styled2ColGrid>
            {AVL_PORTABLE_ICONS.map(({ icon, description }) => (
              <React.Fragment key={description}>
                <StaticIconWrapper icon={icon} size="sm" />
                <StyledText>{description}</StyledText>
              </React.Fragment>
            ))}
          </Styled2ColGrid>
        </Field>
      )}
    </FieldGroup>
  );
};

export default AVLLegend;
