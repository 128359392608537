import getConfig from "next/config";
import AVLLegend from "../../components/legends/AVLLegend/AVLLegend";
import AircraftPositionsLegend from "../../components/legends/AircraftPositionsLegend/AircraftPositionsLegend";
import DEAHotspotsLegend from "../../components/legends/DEAHotspotsLegend/DEAHotspotsLegend";
import EmergencyFacilitiesLegend from "../../components/legends/EmergencyFacilitiesLegend/EmergencyFacilitiesLegend";
import FieldObsIconLegend from "../../components/legends/FieldObsIconLegend/FieldObsIconLegend";
import FireGroundFlightRiskLegend from "../../components/legends/FireGroundFlightRiskLegend/FireGroundFlightRiskLegend";
import HimawariSurfaceTemperatureLegend from "../../components/legends/HimawariSurfaceTemperatureLegend/HimawariSurfaceTemperatureLegend";
import {
  ACTESAResourcesLegend,
  AccommodationFacilitiesLegend,
  AgedCareLegend,
  AirportsLegend,
  BrigadesCoverageLegend,
  BurntAreaCurrentFireSeasonLegend,
  BurntAreaHazardReductionLegend,
  CommunicationTowersLegend,
  EducationFacilitiesLegend,
  EmergencyAlertsLegend,
  FieldObsSitRepUnder1HrLegend,
  FieldObsSitRepUnder3HrsLegend,
  FieldObsWeatherUnder1HrLegend,
  FieldObsWeatherUnder3HrsLegend,
  FireFeaturesLegend,
  FireTrailsLegend,
  FuelLoadLegend,
  FuelTypeLegend,
  HabitablePropertiesLegend,
  HealthFacilitiesLegend,
  InterstateIncidentsLegend,
  LandUseLegend,
  LiveTrafficLegend,
  MobileBlackspotLegend,
  NeighbourhoodSaferPlacesLegend,
  OilAndGasPipelinesLegend,
  PowerInfrastructureLegend,
  RedMapLegend,
  RtaIncidentsLegend,
  SiteInformationLegend,
  StateForestPlantationLegend,
  TelephoneExchangeLegend,
  TotalFireBanAreasLegend,
  WildfireHistoryLegend,
} from "../../components/legends/Legends";
import LightningLegend from "../../components/legends/LightningLegend/LightningLegend";
import GeoserverRasterLegend from "../../components/legends/RasterLegend/RasterLegend";
import SoVIAggregationLegend from "../../components/legends/SoVIAggregationLegend/SoVIAggregationLegend";
import SocialDotLegend from "../../components/legends/SocialDotLegend/SocialDotLegend";
import AerialImageryLayerHint from "../../components/map/AerialImageryLayers/AerialImageryLayerHint";
import FireMapperLegend from "../../components/map/FireMapper/FireMapperLegend";
import {
  fireMapperLayerNames,
  getFireMapperSubLayer,
} from "../../components/map/FireMapper/utils";

const { publicRuntimeConfig } = getConfig();
const { COP_ENABLED } = publicRuntimeConfig;

export enum MapLevel {
  BASE = "baseMapLevel",
  BACKGROUND = "backgroundLevel",
  FS_BACKGROUND = "fsBackgroundLevel",
  LINES = "linesLevel",
  SYMBOLS = "symbolsLevel",
  FS_SYMBOLS = "fsSymbolsLevel",
  FS_SYMBOLS_PRIORITY = "fsSymbolsPriority",
  FS_LABELS = "fsLabelsLevel",
}

export const MapLevels: readonly MapLevel[] = [
  MapLevel.BASE,
  MapLevel.BACKGROUND,
  MapLevel.FS_BACKGROUND,
  MapLevel.LINES,
  MapLevel.FS_LABELS,
  MapLevel.SYMBOLS,
  MapLevel.FS_SYMBOLS,
  MapLevel.FS_SYMBOLS_PRIORITY,
] as const;

export interface MarkupLayer {
  id: string;
  icon: ReactSVGComponent;
  label: string;
}

export interface Legend {
  id: string;
  label: string;
  component: React.ComponentType<{ id?: string }>;
}

export type SubLayerConfig = Omit<
  LayerConfig,
  "legend" | "options" | "subLayers"
>;

export interface LayerHintComponentProps {
  isVisible: boolean;
  layerId: string;
}

export interface LayerConfig {
  disabled?: boolean;
  label: string;
  legend?: Legend;
  hint?: string | undefined;
  hintComponent?: React.ComponentType<LayerHintComponentProps> | undefined;
  id: string;
  requestedPermissions?: readonly string[];
  subLayers?: readonly SubLayerConfig[];
}

export type LayerWithLegend = Required<Pick<LayerConfig, "legend">> &
  LayerConfig;

export type LayerWithSubLayers = Required<Pick<LayerConfig, "subLayers">> &
  LayerConfig;

export const isLayerWithSubLayers = (
  layer: LayerConfig,
): layer is LayerWithSubLayers => !!layer.subLayers?.length;

export interface LayerSet {
  label: string;
  id: string;
  layers: LayerConfig[];
}

export const accommodationFacilitiesLayer = {
  label: "Accommodation facilities",
  id: "accommodationFacilities",
  legend: {
    component: AccommodationFacilitiesLegend,
    id: "accommodationFacilities",
    label: "Accommodation facilities",
  },
} as const satisfies LayerConfig;

export const actEsaResourcesLayer = {
  label: "ACT ESA resources",
  id: "actEsaResourcesLayer",
  legend: {
    component: ACTESAResourcesLegend,
    id: "actEsaResourcesLayer",
    label: "ACT ESA resources",
  },
  requestedPermissions: ["resourcetracking:read"],
} as const satisfies LayerConfig;

export const agedCareLayer = {
  label: "Aged care facilities",
  id: "agedCareFacilities",
  legend: {
    component: AgedCareLegend,
    id: "agedCareFacilities",
    label: "Aged care facilities",
  },
} as const satisfies LayerConfig;

export const aircraftLayer = {
  label: "Aircraft",
  id: "aircraft",
  legend: {
    component: AircraftPositionsLegend,
    id: "aircraft",
    label: "Aircraft",
  },
  requestedPermissions: ["resourcetracking:read"],
} as const satisfies LayerConfig;

export const airportsLayer = {
  label: "Airports & helipads",
  id: "airportsAndHelipads",
  legend: {
    component: AirportsLegend,
    id: "airportsAndHelipads",
    label: "Airports & helipads",
  },
} as const satisfies LayerConfig;

export const avlAppliancesOption = {
  id: "avlAppliancesOption",
  label: "AVL appliances",
} as const satisfies SubLayerConfig;

export const avlPortableOption = {
  id: "avlPortableOption",
  label: "AVL portable radios",
} as const satisfies SubLayerConfig;

export const avlDataLayer = {
  label: "AVL",
  id: "avlData",
  legend: {
    component: AVLLegend,
    id: "avlData",
    label: "AVL",
  },
  requestedPermissions: ["resourcetracking:read"],
  subLayers: COP_ENABLED
    ? ([avlAppliancesOption, avlPortableOption] as const)
    : undefined,
} as const satisfies LayerConfig;

export const avlEmergencyLayer = {
  label: "AVL Emergency",
  id: "avlEmergency",
  legend: {
    component: AVLLegend,
    id: "avlEmergency",
    label: "AVL Emergency",
  },
  requestedPermissions: ["resourcetracking:read"],
} as const satisfies LayerConfig;

export const burntAreaCurrentFireSeasonLayer = {
  label: "Burnt area (current fire season)",
  id: "burntAreaCurrentFireSeason",
  legend: {
    component: BurntAreaCurrentFireSeasonLegend,
    id: "burntAreaCurrentFireSeason",
    label: "Burnt area (current fire season)",
  },
} as const satisfies LayerConfig;

export const burntAreaHazardReductionLayer = {
  label: "Burnt area - hazard reduction",
  id: "burntAreaHazardReduction",
  legend: {
    component: BurntAreaHazardReductionLegend,
    id: "burntAreaHazardReduction",
    label: "Burnt area - hazard reduction",
  },
} as const satisfies LayerConfig;

export const communicationTowersLayer = {
  label: "Communications towers",
  id: "communicationTowers",
  legend: {
    component: CommunicationTowersLegend,
    id: "communicationTowers",
    label: "Communications towers",
  },
} as const satisfies LayerConfig;

export const continuousHaines = {
  label: "Continuous Haines",
  id: "continuousHaines",
  legend: {
    component: GeoserverRasterLegend,
    id: "continuousHaines",
    label: "Continuous Haines",
  },
} as const satisfies LayerConfig;

export const deaHotspotsLayer = {
  label: "DEA hotspots",
  id: "deaHotspots",
  legend: {
    component: DEAHotspotsLegend,
    id: "deaHotspots",
    label: "DEA hotspots",
  },
} as const satisfies LayerConfig;

export const educationFacilitiesLayer = {
  label: "Education facilities",
  id: "educationFacilities",
  legend: {
    component: EducationFacilitiesLegend,
    id: "educationFacilities",
    label: "Education facilities",
  },
} as const satisfies LayerConfig;

export const emergencyAlertsLayer = {
  label: "Emergency alerts",
  id: "emergencyAlerts",
  legend: {
    component: EmergencyAlertsLegend,
    id: "emergencyAlerts",
    label: "Emergency alerts",
  },
} as const satisfies LayerConfig;

export const emergencyFacilitiesLayer = {
  label: "Emergency facilities",
  id: "emergencyFacilities",
  legend: {
    component: EmergencyFacilitiesLegend,
    id: "emergencyFacilities",
    label: "Emergency facilities",
  },
} as const satisfies LayerConfig;

export const fieldObsIconUnder1HrLayer = {
  label: "Field obs - ICON (under 1 hr)",
  id: "fieldObsIconUnder1Hr",
  legend: {
    component: FieldObsIconLegend,
    id: "fieldObsIconUnder1Hr",
    label: "Icon Field Observations",
  },
} as const satisfies LayerConfig;

export const fieldObsIconUnder3HrsLayer = {
  label: "Field obs - ICON (under 3 hrs)",
  id: "fieldObsIconUnder3Hrs",
  legend: {
    component: FieldObsIconLegend,
    id: "fieldObsIconUnder3Hrs",
    label: "Icon Field Observations",
  },
} as const satisfies LayerConfig;

export const fieldObsSitRepUnder1HrLayer = {
  label: "Field obs - SitReps (under 1 hr)",
  id: "fieldObsSitRepUnder1Hr",
  legend: {
    component: FieldObsSitRepUnder1HrLegend,
    id: "fieldObsSitRepUnder1Hr",
    label: "Field obs - SitReps (under 1 hr)",
  },
} as const satisfies LayerConfig;

export const fieldObsSitRepUnder3HrsLayer = {
  label: "Field obs - SitReps (under 3 hrs)",
  id: "fieldObsSitRepUnder3Hrs",
  legend: {
    component: FieldObsSitRepUnder3HrsLegend,
    id: "fieldObsSitRepUnder3Hrs",
    label: "Field obs - SitReps (under 3 hrs)",
  },
} as const satisfies LayerConfig;

export const fieldObsWeatherUnder1HrLayer = {
  label: "Field obs - weather (under 1 hr)",
  id: "fieldObsWeatherUnder1Hr",
  legend: {
    component: FieldObsWeatherUnder1HrLegend,
    id: "fieldObsWeatherUnder1Hr",
    label: "Field obs - weather (under 1 hr)",
  },
} as const satisfies LayerConfig;

export const fieldObsWeatherUnder3HrsLayer = {
  label: "Field obs weather (under 3 hrs)",
  id: "fieldObsWeatherUnder3Hrs",
  legend: {
    component: FieldObsWeatherUnder3HrsLegend,
    id: "fieldObsWeatherUnder3Hrs",
    label: "Field obs weather (under 3 hrs)",
  },
} as const satisfies LayerConfig;

export const fireDangerRating = {
  label: "Hourly fire danger rating",
  id: "fireDangerRating",
  legend: {
    component: GeoserverRasterLegend,
    id: "fireDangerRating",
    label: "Hourly fire danger rating",
  },
} as const satisfies LayerConfig;

export const fireGroundFlightRiskLayer = {
  label: "Fireground flight risk",
  id: "fireGroundFlightRisk",
  legend: {
    component: FireGroundFlightRiskLegend,
    id: "fireGroundFlightRisk",
    label: "Fireground flight risk",
  },
  requestedPermissions: ["aviation-safety:read"],
} as const satisfies LayerConfig;

export const fireMapper1HrLayer = {
  label: "FireMapper (under 1 hr)",
  id: "fireMapper1Hr",
  legend: {
    component: FireMapperLegend,
    id: "fireMapper",
    label: "FireMapper",
  },
  subLayers: fireMapperLayerNames.map((layer) =>
    getFireMapperSubLayer(layer, "1Hr"),
  ),
} as const satisfies LayerConfig;

export const fireMapper3HrsLayer = {
  label: "FireMapper (under 3 hrs)",
  id: "fireMapper3Hrs",
  legend: {
    component: FireMapperLegend,
    id: "fireMapper",
    label: "FireMapper",
  },
  subLayers: fireMapperLayerNames.map((layer) =>
    getFireMapperSubLayer(layer, "3Hrs"),
  ),
} as const satisfies LayerConfig;

export const fireMapper24HrsLayer = {
  label: "FireMapper (under 24 hrs)",
  id: "fireMapper24Hrs",
  legend: {
    component: FireMapperLegend,
    id: "fireMapper",
    label: "FireMapper",
  },
  subLayers: fireMapperLayerNames.map((layer) =>
    getFireMapperSubLayer(layer, "24Hrs"),
  ),
} as const satisfies LayerConfig;

export const fesmOneToThreeYearsLayer = {
  label: "FESM (1-3 years)",
  id: "fesm-1-3",
};

export const fesmFourToSevenYearsLayer = {
  label: "FESM (4-7 years)",
  id: "fesm-4-7",
};

export const fesmSevenPlusYearsLayer = {
  label: "FESM (7+ years)",
  id: "fesm-7+",
};

export const fireTrailsLayer = {
  label: "Fire trails",
  id: "fireTrails",
  legend: {
    component: FireTrailsLegend,
    id: "fireTrails",
    label: "Fire access & fire trails",
  },
} as const satisfies LayerConfig;

export const fourToSevenYearsLayer = {
  label: "Wildfire history (4-7 years)",
  legend: {
    component: WildfireHistoryLegend,
    id: "wildfireHistory",
    label: "Wildfire history",
  },
  id: "fourToSevenYears",
} as const satisfies LayerConfig;

export const frnswJurisdictionalBoundariesLayer = {
  label: "FRNSW jurisdictional boundaries",
  id: "frnswJurisdictionalBoundaries",
} as const satisfies LayerConfig;

export const fuelTypeLayer = {
  label: "Fuel type",
  id: "fuelType",
  legend: {
    component: FuelTypeLegend,
    id: "fuelType",
    label: "Fuel Type",
  },
} as const satisfies LayerConfig;

export const governmentRadioNetworkLayer = {
  label: "GRN",
  id: "governmentRadioNetwork",
} as const satisfies LayerConfig;

export const habitablePropertiesOption = {
  id: "habitablePropertiesOption",
  label: "Show habitable properties",
} as const satisfies SubLayerConfig;

export const habitablePropertiesSwimmingPoolsOption = {
  id: "habitablePropertiesSwimmingPools",
  label: "Show swimming pools",
} as const satisfies SubLayerConfig;

export const habitablePropertiesLayer = {
  label: "Habitable properties",
  id: "habitableProperties",
  legend: {
    component: HabitablePropertiesLegend,
    id: "habitableProperties",
    label: "Habitable properties",
  },
  subLayers: [
    habitablePropertiesOption,
    habitablePropertiesSwimmingPoolsOption,
  ] as const,
} as const satisfies LayerConfig;

export const healthFacilitiesLayer = {
  label: "Health facilities",
  id: "healthFacilities",
  legend: {
    component: HealthFacilitiesLegend,
    id: "healthFacilities",
    label: "Health facilities",
  },
} as const satisfies LayerConfig;

export const himawariSurfaceTemperature = {
  label: "Surface temperature",
  id: "himawariSurfaceTemperature",
  legend: {
    component: HimawariSurfaceTemperatureLegend,
    id: "himawariSurfaceTemperature",
    label: "Surface temperature",
  },
} as const satisfies LayerConfig;

export const infraredCompositeLayer = {
  label: "Infrared composite (hotspots & fire intensity)",
  hintComponent: AerialImageryLayerHint,
  id: "tc",
} as const satisfies LayerConfig;

export const interstateIncidentsACTOption = {
  id: "interstateIncidentsACT",
  label: "ACT",
} as const satisfies SubLayerConfig;

export const interstateIncidentsQLDOption = {
  id: "interstateIncidentsQLD",
  label: "Queensland",
} as const satisfies SubLayerConfig;

export const interstateIncidentsSAOption = {
  id: "interstateIncidentsSA",
  label: "South Australia",
} as const satisfies SubLayerConfig;

export const interstateIncidentsVICOption = {
  id: "interstateIncidentsVIC",
  label: "Victoria",
} as const satisfies SubLayerConfig;

export const interstateIncidentsLayer = {
  id: "interstateIncidents",
  label: "Interstate incidents",
  legend: {
    component: InterstateIncidentsLegend,
    id: "interstateIncidents",
    label: "Interstate incidents",
  },
  subLayers: [
    interstateIncidentsACTOption,
    interstateIncidentsQLDOption,
    interstateIncidentsSAOption,
    interstateIncidentsVICOption,
  ] as const,
} as const satisfies LayerConfig;

export const landUseLayer = {
  label: "Land use",
  id: "landUse",
  legend: {
    component: LandUseLegend,
    id: "landUse",
    label: "Land Use",
  },
} as const satisfies LayerConfig;

export const lightningLayer = {
  label: "Lightning",
  id: "lightning",
  legend: {
    component: LightningLegend,
    id: "lightning",
    label: "Lightning",
  },
} as const satisfies LayerConfig;

export const linescanLayer = {
  label: "Linescan",
  hintComponent: AerialImageryLayerHint,
  id: "linescan",
} as const satisfies LayerConfig;

export const liveTrafficLayer = {
  label: "Traffic",
  id: "liveTraffic",
  legend: {
    component: LiveTrafficLegend,
    id: "liveTraffic",
    label: "Traffic",
  },
} as const satisfies LayerConfig;

export const longWaveLayer = {
  label: "Long wave",
  hintComponent: AerialImageryLayerHint,
  id: "lwir",
} as const satisfies LayerConfig;

export const mediumWaveLayer = {
  label: "Medium wave",
  hintComponent: AerialImageryLayerHint,
  id: "mwir",
} as const satisfies LayerConfig;

export const mediumWaveLongWaveLayer = {
  label: "Medium & long wave",
  hintComponent: AerialImageryLayerHint,
  id: "mwir_lwir",
} as const satisfies LayerConfig;

export const mixingHeight = {
  label: "Mixing height",
  id: "mixingHeight",
  legend: {
    component: GeoserverRasterLegend,
    id: "mixingHeight",
    label: "Mixing height",
  },
} as const satisfies LayerConfig;

export const mobileBlackspotsLayer = {
  label: "Mobile blackspots",
  id: "mobileBlackspots",
  legend: {
    component: MobileBlackspotLegend,
    id: "mobileBlackspots",
    label: "Mobile blackspots",
  },
} as const satisfies LayerConfig;

export const nearInfraRedLayer = {
  label: "Near infrared",
  hintComponent: AerialImageryLayerHint,
  id: "nir",
} as const satisfies LayerConfig;

export const nearInfraRedCompositeLayer = {
  label: "Near infrared composite (landscape imagery)",
  hintComponent: AerialImageryLayerHint,
  id: "lnv",
} as const satisfies LayerConfig;

export const neighbourhoodSaferPlacesLayer = {
  label: "Neighbourhood safer places",
  id: "neighbourhoodSaferPlaces",
  legend: {
    component: NeighbourhoodSaferPlacesLegend,
    id: "neighbourhoodSaferPlaces",
    label: "Neighbourhood safer places",
  },
} as const satisfies LayerConfig;

export const rartStandbyTodayLayer = {
  label: "RART standby (today)",
  id: "rartStandbyTodayLayer",
} as const satisfies LayerConfig;

export const alertStatusDistrictTodayLayer = {
  label: "Alert status - district (today)",
  id: "alertStatusDistrictTodayLayer",
} as const satisfies LayerConfig;

export const alertStatusStateTodayLayer = {
  label: "Alert status - state (today)",
  id: "alertStatusStateTodayLayer",
} as const satisfies LayerConfig;

export const nswfrBoundariesLayer = {
  label: "NSWFR boundaries",
  id: "nswfrBoundaries",
} as const satisfies LayerConfig;

export const oilAndGasPipelinesLayer = {
  label: "Oil & gas pipelines",
  id: "oilAndGasPipelines",
  legend: {
    component: OilAndGasPipelinesLegend,
    id: "oilAndGasPipelines",
    label: "Oil & gas pipelines",
  },
} as const satisfies LayerConfig;

export const oneToThreeYearsLayer = {
  label: "Wildfire history (1-3 years)",
  id: "oneToThreeYears",
  legend: {
    component: WildfireHistoryLegend,
    id: "wildfireHistory",
    label: "Wildfire history",
  },
} as const satisfies LayerConfig;

export const powerLayer = {
  label: "Power infrastructure",
  id: "powerInfra",
  legend: {
    component: PowerInfrastructureLegend,
    id: "powerInfra",
    label: "Power infrastructure",
  },
} as const satisfies LayerConfig;

export const visibleImageLayer = {
  label: "Visible image (smoke plume & travel)",
  hintComponent: AerialImageryLayerHint,
  id: "rgb",
} as const satisfies LayerConfig;

export const visibleNirLayer = {
  label: "Visible image & NIR (RGBN)",
  hintComponent: AerialImageryLayerHint,
  id: "rgbn",
} as const satisfies LayerConfig;

export const redMapTodayLayer = {
  label: "Today",
  id: "redMapToday",
} as const satisfies SubLayerConfig;

export const redMapTomorrowLayer = {
  label: "Tomorrow",
  id: "redMapTomorrow",
} as const satisfies SubLayerConfig;

export const redMapLayer = {
  label: "Red Map",
  id: "redMap",
  legend: {
    component: RedMapLegend,
    id: "redMap",
    label: "Red Map",
  },
  subLayers: [redMapTodayLayer, redMapTomorrowLayer] as const,
} as const satisfies LayerConfig;

export const relativeHumidity = {
  label: "Relative humidity",
  id: "relativeHumidity",
  legend: {
    component: GeoserverRasterLegend,
    id: "relativeHumidity",
    label: "Relative humidity",
  },
} as const satisfies LayerConfig;

export const rfsBrigades = {
  label: "RFS brigades",
  id: "rfsBrigades",
  legend: {
    component: BrigadesCoverageLegend,
    id: "rfsBrigades",
    label: "RFS Brigades coverage",
  },
  requestedPermissions: ["brigade:read"],
} as const satisfies LayerConfig;

export const rfsDistricts = {
  label: "RFS districts",
  id: "rfsDistricts",
} as const satisfies LayerConfig;

export const rtaIncidents = {
  label: "RTA incidents",
  id: "rtaIncidents",
  legend: {
    component: RtaIncidentsLegend,
    id: "rtaIncidents",
    label: "RTA incidents",
  },
} as const satisfies LayerConfig;

export const sevenPlusYearsLayer = {
  label: "Wildfire history (7+ years)",
  id: "sevenPlusYears",
  legend: {
    component: WildfireHistoryLegend,
    id: "wildfireHistory",
    label: "Wildfire history",
  },
} as const satisfies LayerConfig;

export const seventyFivePercentChancePrecipitation = {
  label: "75% chance precipitation",
  id: "seventyFivePercentChancePrecipitation",
  legend: {
    component: GeoserverRasterLegend,
    id: "seventyFivePercentChancePrecipitation",
    label: "75% chance precipitation",
  },
} as const satisfies LayerConfig;

export const shortWaveLayer = {
  label: "Short wave",
  hintComponent: AerialImageryLayerHint,
  id: "swir",
} as const satisfies LayerConfig;

export const siteInformationLayer = {
  label: "Site information",
  id: "siteInformation",
  legend: {
    component: SiteInformationLegend,
    id: "siteInformation",
    label: "Site information",
  },
} as const satisfies LayerConfig;

export const socialMediaLinkedToIncidentLayer = {
  label: "Linked to incident",
  id: "socialMediaLinkedToIncident",
} as const satisfies SubLayerConfig;

export const socialMediaNearHotspot = {
  label: "Near hotspot",
  id: "socialMediaNearHotspot",
} as const satisfies SubLayerConfig;

export const socialMedia = {
  label: "Social media",
  legend: {
    component: SocialDotLegend,
    id: "socialDot",
    label: "Social media posts",
  },
  id: "socialMedia",
  subLayers: [
    socialMediaLinkedToIncidentLayer,
    socialMediaNearHotspot,
  ] as const,
} as const satisfies LayerConfig;

export const validatedSocialMedia = {
  label: "Validated social media",
  legend: {
    component: SocialDotLegend,
    id: "socialDot",
    label: "Social media posts",
  },
  id: "validatedSocialMedia",
  requestedPermissions: ["social:validated:read"],
} as const satisfies LayerConfig;

export const soviAggregationLayer = {
  label: "SoVI aggregation",
  id: "soviAggregation",
  legend: {
    component: SoVIAggregationLegend,
    id: "soviAggregation",
    label: "SoVI aggregation",
  },
} as const satisfies LayerConfig;

export const stateForestPlantationLayer = {
  label: "State forest plantation",
  id: "stateForestPlantation",
  legend: {
    component: StateForestPlantationLegend,
    id: "stateForestPlantation",
    label: "State forest plantation",
  },
} as const satisfies LayerConfig;

export const telephoneExchangeLayer = {
  label: "Telephone exchange",
  id: "telephoneExchange",
  legend: {
    component: TelephoneExchangeLegend,
    id: "telephoneExchange",
    label: "Telephone exchange",
  },
} as const satisfies LayerConfig;

export const temperature = {
  label: "Hourly temperature",
  id: "temperature",
  legend: {
    component: GeoserverRasterLegend,
    id: "temperature",
    label: "Hourly temperature",
  },
} as const satisfies LayerConfig;

export const totalFuelLoadLayer = {
  label: "Total fuel load",
  id: "totalFuelLoad",
  legend: {
    component: FuelLoadLegend,
    id: "totalFuelLoad",
    label: "Total fuel load",
  },
} as const satisfies LayerConfig;

export const totalFireBanAreasLayer = {
  label: "TOBAN: Total Fire Ban Areas",
  id: "totalFireBanAreas",
  legend: {
    component: TotalFireBanAreasLegend,
    id: "totalFireBanAreas",
    label: "TOBAN: Total Fire Ban Areas",
  },
} as const satisfies LayerConfig;

export const twentyFivePercentChancePrecipitation = {
  label: "25% chance precipitation",
  id: "twentyFivePercentChancePrecipitation",
  legend: {
    component: GeoserverRasterLegend,
    id: "twentyFivePercentChancePrecipitation",
    label: "25% chance precipitation",
  },
} as const satisfies LayerConfig;

export const windChangeDangerFlag = {
  label: "Wind change danger flag",
  id: "windChangeDangerFlag",
  legend: {
    component: GeoserverRasterLegend,
    id: "windChangeDangerFlag",
    label: "Wind change danger flag",
  },
} as const satisfies LayerConfig;

export const windChangeDangerIndex = {
  label: "Wind change danger index",
  id: "windChangeDangerIndex",
  legend: {
    component: GeoserverRasterLegend,
    id: "windChangeDangerIndex",
    label: "Wind change danger index",
  },
} as const satisfies LayerConfig;

export const windDirection = {
  label: "Wind direction",
  id: "windDirection",
} as const satisfies LayerConfig;

export const windDirection1500MElevation = {
  label: "Wind direction (1500m elevation)",
  id: "windDirection1500MElevation",
} as const satisfies LayerConfig;

export const windGustKmH = {
  label: "Wind gust (km/h)",
  id: "windGustKmH",
  legend: {
    component: GeoserverRasterLegend,
    id: "windGustKmH",
    label: "Wind gust (km/h)",
  },
} as const satisfies LayerConfig;

export const windSpeed1500MElevationKmH = {
  label: "Wind speed (1500m elevation) (km/h)",
  id: "windSpeed1500MElevationKmH",
  legend: {
    component: GeoserverRasterLegend,
    id: "windSpeed1500MElevationKmH",
    label: "Wind speed (1500m elevation) (km/h)",
  },
} as const satisfies LayerConfig;

export const windSpeedKmH = {
  label: "Wind speed (km/h)",
  id: "windSpeedKmH",
  legend: {
    component: GeoserverRasterLegend,
    id: "windSpeedKmH",
    label: "Wind speed (km/h)",
  },
} as const satisfies LayerConfig;

export const terrainLayer = {
  disabled: false,
  label: "3D",
  id: "terrain",
} as const satisfies LayerConfig;

export const showMarkupLayer = {
  disabled: false,
  label: "Show markup",
  id: "showMarkup",
} as const satisfies LayerConfig;

export const fireFeatures = {
  label: "Fire features",
  id: "fireFeatures",
  legend: {
    component: FireFeaturesLegend,
    label: "Fire features",
    id: "fireFeatures",
  },
} as const satisfies LayerConfig;

export const incidentIcons = {
  label: "Incidents",
  id: "incidentIcons",
} as const satisfies LayerConfig;

export const potentialImpactsCounts = {
  hint: "Displays potential impact counts when predictions are switched on",
  label: "Impact counts",
  id: "impactCounts",
} as const satisfies LayerConfig;

export const stateBorder = {
  label: "State border",
  id: "stateBorder",
} as const satisfies LayerConfig;
